// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cookiePolicyPage {
  min-height: 100vh;
}
.cookiePolicyPage .cookies-container {
  display: flex;
  justify-content: center;
}
.cookiePolicyPage .cookies-container .cookies-content {
  margin-top: 12%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 30px;
  max-width: 750px;
  text-align: center;
}
.cookiePolicyPage .cookies-container .cookies-content h1 {
  color: #333;
  margin-bottom: 20px;
}
.cookiePolicyPage .cookies-container .cookies-content p {
  font-size: 18px;
  color: #555;
  line-height: 1.5;
}
.cookiePolicyPage .cookies-container .cookies-content a {
  color: #269ed6;
  text-decoration: none;
}
.cookiePolicyPage .cookies-container .cookies-content a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Component/Cookies/Style.scss"],"names":[],"mappings":"AAGA;EACI,iBAAA;AAFJ;AAII;EACI,aAAA;EACA,uBAAA;AAFR;AAIQ;EACI,eAAA;EACA,yBAAA;EACA,mBAAA;EACA,uCAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;AAFZ;AAIY;EACI,WAAA;EACA,mBAAA;AAFhB;AAKY;EACI,eAAA;EACA,WAAA;EACA,gBAAA;AAHhB;AAMY;EACI,cA9BL;EA+BK,qBAAA;AAJhB;AAMgB;EACI,0BAAA;AAJpB","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n\r\n.cookiePolicyPage {\r\n    min-height: 100vh;\r\n    \r\n    .cookies-container {\r\n        display: flex;\r\n        justify-content: center;\r\n        \r\n        .cookies-content {\r\n            margin-top: 12%;\r\n            background-color: #ffffff;\r\n            border-radius: 10px;\r\n            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\r\n            padding: 30px;\r\n            max-width: 750px;\r\n            text-align: center;\r\n        \r\n            h1 {\r\n                color: #333;\r\n                margin-bottom: 20px;\r\n            }\r\n        \r\n            p {\r\n                font-size: 18px;\r\n                color: #555;\r\n                line-height: 1.5;\r\n            }\r\n        \r\n            a {\r\n                color: $main-clr;\r\n                text-decoration: none;\r\n                \r\n                &:hover {\r\n                    text-decoration: underline;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
