// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.particles-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.particle {
  position: absolute;
  width: 8px;
  height: 8px;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #ffffff;
  transition: border-color 0.3s ease-in-out;
}

.particle:hover {
  background-color: #13E8E9;
}

.particle.connected {
  border-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/Component/Demo/Style.scss"],"names":[],"mappings":"AA0BA;EACE,kBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AAzBF;;AA4BA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,6BAAA;EACA,kBAAA;EACA,yBAAA;EACA,yCAAA;AAzBF;;AA4BA;EACE,yBAAA;AAzBF;;AA4BA;EACE,qBAAA;AAzBF","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n$light-dark-clr: #434449;\r\n$whiteClr: #fff;\r\n$main-light-clr: #269ed609;\r\n$border-clr: #6ebbdf48;\r\n$title-font-size: 3rem;\r\n$card-title: 1.5rem;\r\n$paragraph-font-size: 1.2rem !default;\r\n\r\n// ?? Main Mixins\r\n@mixin flex {\r\n  display: flex !important;\r\n  margin: auto;\r\n  justify-content: center !important;\r\n  place-items: center !important;\r\n}\r\n\r\n@mixin h1 {\r\n  padding: 1%;\r\n  text-align: center;\r\n  font-family: \"Poppins\";\r\n}\r\n\r\n\r\n.particles-container {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100vh;\r\n  overflow: hidden;\r\n}\r\n\r\n.particle {\r\n  position: absolute;\r\n  width: 8px;\r\n  height: 8px;\r\n  border: 1px solid transparent;\r\n  border-radius: 50%;\r\n  background-color: #ffffff;\r\n  transition: border-color 0.3s ease-in-out;\r\n}\r\n\r\n.particle:hover {\r\n  background-color: #13E8E9;\r\n}\r\n\r\n.particle.connected {\r\n  border-color: #ffffff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
