// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-in-section {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  will-change: opacity, transform; /* Optimize for smoother animations */
}

.fade-in-section.active {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s; /* Add a slight delay for a staggered effect */
}

.fade-in-section.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/Defaults/Animations/Style.scss"],"names":[],"mappings":"AACA;EACI,UAAA;EACA,4BAAA;EACA,kDACI;EAEJ,+BAAA,EAAA,qCAAA;AAFJ;;AAKA;EACI,UAAA;EACA,wBAAA;EACA,sBAAA,EAAA,8CAAA;AAFJ;;AAKA;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;AAFJ","sourcesContent":["// ************************* Animated Sections *******************\r\n.fade-in-section {\r\n    opacity: 0;\r\n    transform: translateY(100px);\r\n    transition:\r\n        opacity 0.5s ease,\r\n        transform 0.5s ease;\r\n    will-change: opacity, transform; /* Optimize for smoother animations */\r\n}\r\n\r\n.fade-in-section.active {\r\n    opacity: 1;\r\n    transform: translateY(0);\r\n    transition-delay: 0.2s; /* Add a slight delay for a staggered effect */\r\n}\r\n\r\n.fade-in-section.active::before {\r\n    content: \"\";\r\n    position: absolute;\r\n    width: 100%;\r\n    height: 100%;\r\n    opacity: 0.5;\r\n    pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
