// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.annualReturn-container .annualReturn .annualReturn-text {
  padding-top: 5%;
}
.annualReturn-container .annualReturn .annualReturn-text a {
  text-decoration: none;
  color: #808080;
}
.annualReturn-container .annualReturn .annualReturn-text a i {
  color: #ff6600;
}
.annualReturn-container .annualReturn .annualReturn-text a:hover {
  color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Footers/AnnualReturns/Style.scss"],"names":[],"mappings":"AAOQ;EACI,eAAA;AANZ;AAQY;EACI,qBAAA;EACA,cAVN;AAIV;AAQgB;EACI,cAAA;AANpB;AASgB;EACI,cAlBT;AAWX","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n// ** Styles\r\n.annualReturn-container {\r\n    .annualReturn {\r\n        .annualReturn-text {\r\n            padding-top: 5%;\r\n\r\n            a {\r\n                text-decoration: none;\r\n                color: $sec-clr;\r\n\r\n                i {\r\n                    color: #ff6600;\r\n                }\r\n\r\n                &:hover {\r\n                    color: $main-clr;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
