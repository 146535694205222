// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadContainer {
  min-height: 100vh;
}
.downloadContainer .downloadFormContainer {
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.downloadContainer .downloadFormContainer .downloadsForm {
  transition: all 0.3s ease-in-out;
}
.downloadContainer .downloadFormContainer .downloadsForm:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.downloadContainer .downloadFormContainer .downloadsForm th,
.downloadContainer .downloadFormContainer .downloadsForm td {
  padding: 10px;
}
.downloadContainer .downloadFormContainer .downloadsForm a {
  color: #269ed6;
  text-decoration: none;
  transition: color 0.3s;
}
.downloadContainer .downloadFormContainer .downloadsForm a:hover {
  color: #01547a;
}`, "",{"version":3,"sources":["webpack://./src/Component/Footers/Downloads/Style.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;EACA,2CAAA;EACA,aAAA;AACR;AACQ;EACI,gCAAA;AACZ;AACY;EACI,2CAAA;AAChB;AAEY;;EAEI,aAAA;AAAhB;AAGY;EACI,cAAA;EACA,qBAAA;EACA,sBAAA;AADhB;AAGgB;EACI,cAAA;AADpB","sourcesContent":[".downloadContainer {\r\n    min-height: 100vh;\r\n\r\n    .downloadFormContainer {\r\n        margin-top: 40px;\r\n        border-radius: 10px;\r\n        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\r\n        padding: 20px;\r\n\r\n        .downloadsForm {\r\n            transition: all .3s ease-in-out;\r\n            \r\n            &:hover {\r\n                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\r\n            }\r\n\r\n            th,\r\n            td {\r\n                padding: 10px;\r\n            }\r\n\r\n            a {\r\n                color: #269ed6;\r\n                text-decoration: none;\r\n                transition: color 0.3s;\r\n\r\n                &:hover {\r\n                    color: #01547a;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
