// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.privacy_policy-conatiner {
  margin-top: 2%;
}

.privacy_policy-conatiner h1 span::after {
  content: "🔏";
  position: absolute;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.pivacy_policy-row .privacy_policy-text p.disc span {
  font-size: 3rem;
  color: #269ed6;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/PrivacyPolicy/Style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAMhB;EACI,cAAA;AAJJ;;AAOA;EACI,aAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAJJ;;AAOA;EACI,eAjBc;EAkBd,cAnBO;EAoBP,gBAAA;AAJJ","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$title-font-size: 3rem !default;\r\n\r\n\r\n// ***** styles\r\n.privacy_policy-conatiner {\r\n    margin-top: 2%;\r\n}\r\n\r\n.privacy_policy-conatiner h1 span::after {\r\n    content: \"🔏\";\r\n    position: absolute;\r\n    width: 50px;\r\n    height: 50px;\r\n    margin-left: 10px;\r\n}\r\n\r\n.pivacy_policy-row .privacy_policy-text p.disc span {\r\n    font-size: $title-font-size;\r\n    color: $main-clr;\r\n    font-weight: 600;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
