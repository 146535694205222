// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arbitration_rules .arbitration_rules-texts h4.disc {
  padding-top: 4%;
  color: #269ed6;
  font-family: "Poppins";
}

.arbitration_rules_qa-section {
  background-color: rgba(38, 158, 214, 0.0352941176);
  padding-top: 2%;
  padding-bottom: 5%;
}
.arbitration_rules_qa-section .arbitration_rules-rows h2.subtitle {
  font-size: 2.3rem;
  text-align: center;
  padding-bottom: 1%;
}
.arbitration_rules_qa-section .arbitration_rules-rows h2.subtitle span {
  color: #269ed6;
}
.arbitration_rules_qa-section .arbitration_rules-rows h2.subtitle span::after {
  content: "";
  position: absolute;
  width: 90px;
  height: 3px;
  margin-top: 47px;
  margin-left: -80px;
  background-color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/ArbitrationRules/Style.scss"],"names":[],"mappings":"AAWQ;EACI,eAAA;EACA,cAZD;EAaC,sBAAA;AAVZ;;AAgBA;EACI,kDAlBa;EAmBb,eAAA;EACA,kBAAA;AAbJ;AAgBQ;EACI,iBAAA;EACA,kBAAA;EACA,kBAAA;AAdZ;AAgBY;EACI,cA/BL;AAiBX;AAgBgB;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAxCT;AA0BX","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n$main-light-clr: #269ed609;\r\n\r\n\r\n// ***** Styling ***** \r\n.arbitration_rules {\r\n\r\n    .arbitration_rules-texts {\r\n\r\n        h4.disc {\r\n            padding-top: 4%;\r\n            color: $main-clr;\r\n            font-family: 'Poppins';\r\n        }\r\n    }\r\n\r\n}\r\n\r\n.arbitration_rules_qa-section {\r\n    background-color: $main-light-clr;\r\n    padding-top: 2%;\r\n    padding-bottom: 5%;\r\n\r\n    .arbitration_rules-rows {\r\n        h2.subtitle {\r\n            font-size: 2.3rem;\r\n            text-align: center;\r\n            padding-bottom: 1%;\r\n\r\n            span {\r\n                color: $main-clr;\r\n\r\n                &::after {\r\n                    content: \"\";\r\n                    position: absolute;\r\n                    width: 90px;\r\n                    height: 3px;\r\n                    margin-top: 47px;\r\n                    margin-left: -80px;\r\n                    background-color: $main-clr;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
