// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-container {
  margin: 3% 0;
  min-height: 100vh;
}
.data-container .data .data-img {
  display: flex;
  justify-content: center;
  place-items: center;
}
.data-container .data .data-img .imgData {
  width: 300px;
}
.data-container .data .data-text .tableContainer {
  height: 400px;
  overflow-y: scroll;
}
.data-container .data .data-text .tableContainer .sticky-header {
  padding: 0;
  margin: 0;
  position: sticky;
  top: 0;
  background-color: white; /* Adjust as needed */
  z-index: 1;
}
.data-container .data .data-text a {
  text-decoration: none;
  color: #808080;
}
.data-container .data .data-text a i {
  color: #ff9900;
}
.data-container .data .data-text a:hover {
  color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/Data/Style.scss"],"names":[],"mappings":"AAMA;EACI,YAAA;EACA,iBAAA;AALJ;AASQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAPZ;AASY;EACI,YAAA;AAPhB;AAYY;EACI,aAAA;EACA,kBAAA;AAVhB;AAYgB;EACI,UAAA;EACA,SAAA;EACA,gBAAA;EACA,MAAA;EACA,uBAAA,EAAA,qBAAA;EACA,UAAA;AAVpB;AAaY;EACI,qBAAA;EACA,cApCN;AAyBV;AAagB;EACI,cAAA;AAXpB;AAcgB;EACI,cA5CT;AAgCX","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n\r\n// ** Styles \r\n.data-container {\r\n    margin: 3% 0;\r\n    min-height: 100vh;\r\n\r\n    .data {\r\n\r\n        .data-img {\r\n            display: flex;\r\n            justify-content: center;\r\n            place-items: center;\r\n\r\n            .imgData {\r\n                width: 300px;\r\n            }\r\n        }\r\n        .data-text {\r\n\r\n            .tableContainer {\r\n                height: 400px;\r\n                overflow-y: scroll;\r\n\r\n                .sticky-header {\r\n                    padding: 0;\r\n                    margin: 0;\r\n                    position: sticky;\r\n                    top: 0;\r\n                    background-color: white; /* Adjust as needed */\r\n                    z-index: 1;\r\n                }\r\n            }\r\n            a {\r\n                text-decoration: none;\r\n                color: $sec-clr;\r\n                \r\n                i {\r\n                    color: #ff9900;\r\n                }\r\n                \r\n                &:hover {\r\n                    color: $main-clr;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
