import React from "react";
import { useState } from "react";
import {
  Col,
  Container,
  Row
} from "react-bootstrap";
import Pdf from "./PDFs/EscalationMatrix.pdf";
import Annual from "./PDFs/annual.pdf";
import Scores from "./Scores";
import AFI from './PDFs/AFI.pdf'
import ACForm from './PDFs/ACForm.pdf'
import scheme from './PDFs/SchemeofArrangementRevised.pdf'
import SecuredCreditors from './PDFs/SecuredCreditorsMeetingSchemeOfArrangement.pdf'
import UnsecuredCreditors from './PDFs/UnsecuredCreditorsMeetingSchemeOfArrangement.pdf'


const FooterLinks = () => {

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <section className="footer-links links-container">
        <Container className="footer-links">
          <Row className="footer-links-rows">
            <Col lg={3} md={6} sm={6} xs={12} className="footer-sub-links">
              <h4 className="subtitle">Quick Links</h4>
              <ul className="link-styles small-tab">
                <li className="link-css">
                  <a className="anc-link" href="/initial-public-offering-ipo">IPOs</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/careers">Careers</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/about-us">About Us</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/mutual-fund">Mutual Funds</a>
                </li>
                <li className="link-css">
                  <a href="/fund-transfer" className="anc-link">Fund Transfer</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/equity-trading">Equity Trading</a>
                </li>  
                <li className="link-css">
                  <a className="anc-link" href="/blogs">Read Our Blogs</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/knowledgeCenter">
                    Knowledge Center
                  </a>
                </li>              
                <li className="link-css">
                  <a href="/algorithmic-trading" className="anc-link">Algorithmic Trading</a>
                </li>
                <li className="link-css">
                  <a href="/high-frequency-trading" className="anc-link">High Frequency Trading</a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="footer-sub-links">
              <h4 className="subtitle">INVESTOR</h4>
              <ul className="link-styles">
                <li className="link-css">
                  <button className="anc-link" href="#SCORES" onClick={() => setModalShow(true)}>Scores</button>
                  <Scores show={modalShow} onHide={() => setModalShow(false)} />
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/evoting" title="Shareholders/Members">
                    e-Voting
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/do-dont" title="investor charter">
                    Do's & Don'ts
                  </a>
                </li>
                {/* <li className="link-css">
                  <a className="anc-link" href={Annual} rel="noopener noreferrer" target="_blank">
                    Annual Return
                  </a>
                </li> */}
                <li className="link-css">
                  <a className="anc-link" href="/bankdetails">
                    Bank DP Details
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="investor-charters" rel="noopener noreferrer" target="_blank">
                    Investor Charter
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href={Pdf} rel="noopener noreferrer" target="_blank">
                    Escalation Matrix
                  </a>
                </li>
                {/* <li className="link-css">
                  <a className="anc-link" href="/client-bank-details" rel="noopener noreferrer" target="_blank">
                    Client Bank Details
                  </a>
                </li> */}
                <li className="link-css">
                  <a className="anc-link" href="/mandatory-details" rel="noopener noreferrer" target="_blank">
                    Mandatory Details
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href={AFI} rel="noopener noreferrer" target="_blank">
                    Advisory for Investor
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="/investor-complaints-data" rel="noopener noreferrer" target="_blank">
                    Investor Complaints
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="footer-sub-links">
              <h4 className="subtitle">SUPPORT</h4>
              <ul className="link-styles small-tab">
                <li className="link-css">
                  <a className="anc-link" href="faq">
                    F.A.Qs
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="sitemap">
                    Sitemap
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="support">
                    Help Desk
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="contact">
                    Contact Us
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="downloads">
                    Downloads
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="ticket">
                    Create a ticket
                  </a>
                </li>
                <li className="link-css" title='account closure'>
                  <a href="https://closure.growthsec.com/cloud_closure/closure/Growthsec" className='anc-link'>Account Closure</a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="calculators">
                    Growth Calculators
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="step-by-step" rel="noopener noreferrer" target="_blank">
                    Step By Step Procedures
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="crd" rel="noopener noreferrer" target="_blank">
                    Client Registration Details
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className="footer-sub-links">
              <h4 className="subtitle">LEGAL</h4>
              <ul className="link-styles small-tab">
                
                <li className="link-css">
                  <a className="anc-link" href="refund-policy" rel="noopener noreferrer" target="_blank">
                    Refund Policy
                  </a>
                </li>
                <li className="link-css">
                  <a
                    className="anc-link" href="privacy-policy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="broker-norms" rel="noopener noreferrer" target="_blank">
                    Broker Norms
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="risk-disclosure" rel="noopener noreferrer" target="_blank">
                    Risk Disclosure
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="arbitration-rules" rel="noopener noreferrer" target="_blank">
                    Arbitration Rules
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" title="Secured Creditors Meeting Scheme Of Arrangement" href={SecuredCreditors} rel="noopener noreferrer" target="_blank">
                    Secured Creditors
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="terms-conditions" rel="noopener noreferrer" target="_blank">
                    Terms & Conditions
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="policy-procedures" rel="noopener noreferrer" target="_blank">
                    Policy & Procedures
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" title="Unsecured Creditors Meeting Scheme Of Arrangement" href={UnsecuredCreditors} rel="noopener noreferrer" target="_blank">
                    Unsecured Creditors
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" href="cookies" rel="noopener noreferrer" target="_blank">
                    Website Cookie Policy
                  </a>
                </li>
                <li className="link-css">
                  <a className="anc-link" title="Scheme of Arrangement Revised _28.09.2023" href={scheme} rel="noopener noreferrer" target="_blank">
                    Scheme of Arrangement
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <hr width='100%' />
        </Container>
      </section>
    </>
  );
};

export default FooterLinks;
