// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-details-container .client-details {
  padding: 4% 0;
}
.client-details-container .client-details .client-details-rows .client-details-texts {
  padding-top: 9%;
}

.details-container {
  margin-bottom: 4%;
}
.details-container .details {
  overflow: scroll;
}

@media only screen and (max-device-width: 640px) {
  .client-details-container {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/ClientBank/Style.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGY;EACI,eAAA;AADhB;;AAQA;EACI,iBAAA;AALJ;AAOI;EACI,gBAAA;AALR;;AASA;EACI;IACI,kBAAA;EANN;AACF","sourcesContent":[".client-details-container {\r\n    .client-details {\r\n        padding: 4% 0;\r\n        \r\n        .client-details-rows {\r\n            .client-details-texts {\r\n                padding-top: 9%;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n// *** Details\r\n.details-container {\r\n    margin-bottom: 4%;\r\n    \r\n    .details {\r\n        overflow: scroll;\r\n    }\r\n}\r\n\r\n@media only screen and (max-device-width : 640px) {\r\n    .client-details-container {\r\n        text-align: center;\r\n    }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
