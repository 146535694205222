// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.scrolltop {
  position: fixed;
  bottom: 93px;
  left: 10px;
  width: 30px;
  height: 45px;
  cursor: pointer;
  border: 1px solid #04415e;
  opacity: 1;
  border-radius: 15px;
  z-index: 999;
  animation: bounce-top 0.9s 1s infinite alternate-reverse backwards;
}
@keyframes bounce-top {
  0% {
    transform: translateY(-5px);
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
span.scrolltop:active {
  border: 1px solid #04415e;
  background-color: #04415e;
  color: #fff;
}
span.scrolltop:hover {
  opacity: 0.8;
}
span.scrolltop:active i {
  color: #fff;
}
span.scrolltop i {
  color: #04415e;
  display: flex;
  justify-content: center;
  place-items: center;
  padding-top: 12px;
  margin: 0;
  font-size: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./src/Component/ScrollTop/Style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,UAAA;EACA,mBAAA;EACA,YAAA;EAGA,kEAAA;AAAF;AAEE;EACE;IAEE,2BAAA;IAEA,kCAAA;IACA,UAAA;EAAJ;EAGE;IACE,UAAA;EADJ;EAIE;IAEE,0BAAA;IAEA,mCAAA;IACA,UAAA;EAFJ;AACF;AAKE;EACE,yBAAA;EACA,yBAAA;EACA,WAAA;AAHJ;AAME;EACE,YAAA;AAJJ;AAOE;EACE,WAAA;AALJ;AAQE;EACE,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,SAAA;EACA,iBAAA;AANJ","sourcesContent":["span.scrolltop {\r\n  position: fixed;\r\n  bottom: 93px;\r\n  left: 10px;\r\n  width: 30px;\r\n  height: 45px;\r\n  cursor: pointer;\r\n  border: 1px solid #04415e;\r\n  opacity: 1;\r\n  border-radius: 15px;\r\n  z-index: +999;\r\n  // animation: jump .3s infinite .2s;\r\n  -webkit-animation: bounce-top 0.9s 1s infinite alternate-reverse backwards;\r\n  animation: bounce-top 0.9s 1s infinite alternate-reverse backwards;\r\n\r\n  @keyframes bounce-top {\r\n    0% {\r\n      -webkit-transform: translateY(-5px);\r\n      transform: translateY(-5px);\r\n      -webkit-animation-timing-function: ease-in;\r\n      animation-timing-function: ease-in;\r\n      opacity: 1;\r\n    }\r\n\r\n    24% {\r\n      opacity: 1;\r\n    }\r\n\r\n    100% {\r\n      -webkit-transform: translateY(0px);\r\n      transform: translateY(0px);\r\n      -webkit-animation-timing-function: ease-out;\r\n      animation-timing-function: ease-out;\r\n      opacity: 1;\r\n    }\r\n  }\r\n\r\n  &:active {\r\n    border: 1px solid #04415e;\r\n    background-color: #04415e;\r\n    color: #fff;\r\n  }\r\n\r\n  &:hover {\r\n    opacity: 0.8;\r\n  }\r\n\r\n  &:active i {\r\n    color: #fff;\r\n  }\r\n\r\n  i {\r\n    color: #04415e;\r\n    display: flex;\r\n    justify-content: center;\r\n    place-items: center;\r\n    padding-top: 12px;\r\n    margin: 0;\r\n    font-size: 1.1rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
