// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Icons/policyback.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.policy_procedure-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  padding-top: 2%;
}
.policy_procedure-container .policy_procedures-text h2.subtitle {
  color: #808080;
}
.policy_procedure-container .policy_procedures-text p.disc span {
  font-size: 2.7rem;
  color: #269ed6;
  font-weight: 700;
}
.policy_procedure-container .policy_procedures-text li.lists {
  font-family: "Poppins";
  list-style-type: none;
  color: #808080 !important;
}
.policy_procedure-container .policy_procedures-text li.lists::before {
  content: "➧";
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -27px;
  color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/PolicyProcedures/Style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAKhB;EACI,yDAAA;EACA,4BAAA;EACA,eAAA;AAHJ;AAMQ;EACI,cAVF;AAMV;AASY;EACI,iBAAA;EACA,cAlBL;EAmBK,gBAAA;AAPhB;AAWQ;EACI,sBAAA;EACA,qBAAA;EACA,yBAAA;AATZ;AAWY;EACI,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAlCL;AAyBX","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n// ***** Styles ***** \r\n.policy_procedure-container {\r\n    background-image: url(../Icons/policyback.png);\r\n    background-repeat: no-repeat;\r\n    padding-top: 2%;\r\n\r\n    .policy_procedures-text {\r\n        h2.subtitle {\r\n            color: $sec-clr;\r\n        }\r\n\r\n        p.disc {\r\n\r\n            span {\r\n                font-size: 2.7rem;\r\n                color: $main-clr;\r\n                font-weight: 700;\r\n            }\r\n        }\r\n\r\n        li.lists {\r\n            font-family: 'Poppins';\r\n            list-style-type: none;\r\n            color: $sec-clr !important;\r\n\r\n            &::before {\r\n                content: \"➧\";\r\n                position: absolute;\r\n                width: 20px;\r\n                height: 20px;\r\n                margin-left: -27px;\r\n                color: $main-clr;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
