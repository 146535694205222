// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gold_bond-container {
  background-color: rgba(38, 158, 214, 0.137254902);
}
.gold_bond-container .gold_bond-rows .gold_bond-text {
  padding-top: 10%;
}

.bonds_disc-container {
  padding-top: 5%;
  margin-bottom: 5%;
}
.bonds_disc-container .bonds_disc .gold_bond-text {
  padding-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/Component/Bonds/Style.scss"],"names":[],"mappings":"AAKA;EACI,iDAAA;AAJJ;AAQQ;EACI,gBAAA;AANZ;;AAaA;EACI,eAAA;EACA,iBAAA;AAVJ;AAcQ;EACI,eAAA;AAZZ","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n\r\n//*** Styling ********************************\r\n\r\n.gold_bond-container {\r\n    background-color: #269ed623;\r\n\r\n    .gold_bond-rows {\r\n\r\n        .gold_bond-text {\r\n            padding-top: 10%;\r\n        }\r\n    }\r\n}\r\n\r\n\r\n// *** Disc Section ***\r\n.bonds_disc-container {\r\n    padding-top: 5%;\r\n    margin-bottom: 5%;\r\n\r\n    .bonds_disc {\r\n\r\n        .gold_bond-text {\r\n            padding-top: 5%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
