// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.disclaimer-container span::after {
  content: "✍";
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: -7px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/Disclaimer/Style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAOZ;EACI,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AALR","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n// ***** Styles ***** \r\n.disclaimer-container {\r\n\r\n    span::after {\r\n        content: \"✍\";\r\n        position: absolute;\r\n        width: 50px;\r\n        height: 50px;\r\n        margin-top: -7px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
