// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dodont .dodont-rows .dodont-text h1.title {
  color: #269ed6;
}
.dodont .dodont-rows .dodont-text h3.subtitle {
  margin-top: 4%;
}
.dodont .dodont-rows .dodont-text h3.heading {
  color: #808080;
  font-family: "Poppins";
}
.dodont .dodont-rows .dodont-text ul.lists li.list {
  list-style-type: square;
  font-family: "Poppins";
  color: #2b2d42;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/DoDont/Style.scss"],"names":[],"mappings":"AAUY;EACI,cAVL;AACX;AAYY;EACI,cAAA;AAVhB;AAaY;EACI,cAjBN;EAkBM,sBAAA;AAXhB;AAegB;EACI,uBAAA;EACA,sBAAA;EACA,cAxBH;AAWjB","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n$light-dark-clr: #2b2d42;\r\n\r\n\r\n// *** Styles ********************************\r\n.dodont {\r\n    .dodont-rows {\r\n        .dodont-text {\r\n            h1.title {\r\n                color: $main-clr;\r\n            }\r\n\r\n            h3.subtitle {\r\n                margin-top: 4%;\r\n            }\r\n\r\n            h3.heading {\r\n                color: $sec-clr;\r\n                font-family: 'Poppins';\r\n            }\r\n\r\n            ul.lists {\r\n                li.list {\r\n                    list-style-type: square;\r\n                    font-family: 'Poppins';\r\n                    color: $light-dark-clr;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
