// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.refund .term-text h2.subtitle {
  color: #808080;
}
.refund .term-text ul li.list {
  list-style: none;
  font-size: 1rem;
  cursor: default;
}
.refund .term-text ul li.list::before {
  content: "➧";
  position: absolute;
  margin-left: -28px;
  color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/RefundPolicy/Style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAWR;EACI,cAVF;AACV;AAYQ;EACI,gBAAA;EACA,eAAA;EACA,eAAA;AAVZ;AAYY;EACI,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAvBL;AAaX","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n$light-dark-clr: #2b2d42;\r\n$title-font-size: 3rem !default;\r\n\r\n\r\n// *** Styles\r\n.refund {\r\n    .term-text {\r\n\r\n        h2.subtitle {\r\n            color: $sec-clr;\r\n        }\r\n\r\n        ul li.list {\r\n            list-style: none;\r\n            font-size: 1rem;\r\n            cursor: default;\r\n\r\n            &::before {\r\n                content: \"➧\";\r\n                position: absolute;\r\n                margin-left: -28px;\r\n                color: $main-clr;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
