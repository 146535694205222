// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-expired {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.session-expired .session-expired-content {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.session-expired .session-expired-content h1 {
  font-size: 2rem;
  color: #ff5722;
}
.session-expired .session-expired-content p {
  margin: 10px 0;
  font-size: 1.2rem;
}
.session-expired .session-expired-content .action-buttons {
  margin-top: 20px;
}
.session-expired .session-expired-content .action-buttons button {
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  color: #fff;
  transition: background-color 0.3s;
}
.session-expired .session-expired-content .action-buttons button:first-child {
  background-color: #007bff;
}
.session-expired .session-expired-content .action-buttons button:last-child {
  background-color: #28a745;
}
.session-expired .session-expired-content .action-buttons button:hover {
  background-color: rgb(0, 98.4, 204);
}`, "",{"version":3,"sources":["webpack://./src/Component/SessionExpired/Style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AACI;EACE,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,uCAAA;AACN;AACM;EACE,eAAA;EACA,cAAA;AACR;AAEM;EACE,cAAA;EACA,iBAAA;AAAR;AAGM;EACE,gBAAA;AADR;AAGQ;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,iCAAA;AADV;AAGU;EACE,yBAAA;AADZ;AAIU;EACE,yBAAA;AAFZ;AAKU;EACE,mCAAA;AAHZ","sourcesContent":[".session-expired {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n  \r\n    .session-expired-content {\r\n      text-align: center;\r\n      background-color: #f5f5f5;\r\n      border-radius: 10px;\r\n      padding: 20px;\r\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\r\n  \r\n      h1 {\r\n        font-size: 2rem;\r\n        color: #ff5722; // Custom color\r\n      }\r\n  \r\n      p {\r\n        margin: 10px 0;\r\n        font-size: 1.2rem;\r\n      }\r\n  \r\n      .action-buttons {\r\n        margin-top: 20px;\r\n  \r\n        button {\r\n          padding: 10px 20px;\r\n          margin: 0 10px;\r\n          font-size: 1rem;\r\n          cursor: pointer;\r\n          border: none;\r\n          border-radius: 5px;\r\n          color: #fff;\r\n          transition: background-color 0.3s;\r\n  \r\n          &:first-child {\r\n            background-color: #007bff; // Blue color\r\n          }\r\n  \r\n          &:last-child {\r\n            background-color: #28a745; // Green color\r\n          }\r\n  \r\n          &:hover {\r\n            background-color: darken(#007bff, 10%); // Darken the blue color on hover\r\n          }\r\n        }\r\n      }\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
