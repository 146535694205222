import React, { useState } from "react";
import "./Style.scss";
import SideNav from "../SideNav/SideNav";
import brand from "../Icons/brand.png";
import SearchFilter from "../SearchFilter/SearchFilter";

// *** Website Nav Icons -- About
import about from "../Website-Icons/about.png";
import careers from "../Website-Icons/careers.png";
import business from "../Website-Icons/business.png";
import research from "../Website-Icons/research.png";
import blogs from "../Website-Icons/blog.png";
import contact from "../Website-Icons/contact.png";
import feedback from "../Website-Icons/feedback.png";

// *** Website Nav Icons -- Markets
import bonds from "../Website-Icons/bonds.svg";
import mutualFunds from "../Website-Icons/mutual-fund.png";
import equity from "../Website-Icons/equity.png";
import currency from "../Website-Icons/forex.png";
import derivative from "../Website-Icons/derivatives.png";
import commodity from "../Website-Icons/commodity.png";
import algo from "../Website-Icons/Algo-trade.jpg";
import hft from "../Website-Icons/hft.png";
import ipo from "../Website-Icons/ipo.png";
import etf from "../Website-Icons/etf.png";
import refer from "../Website-Icons/refer.png";
import help from "../Website-Icons/supports.png";
import depository from "../Website-Icons/dipository.jpg";
import transfer from "../Website-Icons/transfer.png";
import bankDP from "../Website-Icons/bank-dp.png";
import knowledgeCenter from "../Website-Icons/knowledge-center.png";
import calc from "../Website-Icons/calc.png";
import quiz from "../Website-Icons/quiz.png";
import simulation from "../Website-Icons/simulation.png";
import summary from "../Website-Icons/summary.png";
import indicator from "../Website-Icons/indicator.png";
import news from "../Website-Icons/news.png";

// *** Website Nav Icons -- Accounts
import activate from "../Website-Icons/activate.png";
import risk from "../Website-Icons/risk.png";
import NavCar from "./NavCar";
import Weather from "../../Component/Weather/Weather";
import SearchContainer from "../SearchFilter/SearchContainer";

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").style.top = "0";
  } else {
    document.getElementById("navbar").style.top = "-100px";
  }
  prevScrollpos = currentScrollPos;
};

const NavBar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg" id="navbar">
        <div className="container-fluid toggle_icon">
          <a className="navbar-brand" href="/">
            <img
              className="brand"
              src={brand}
              alt="Growth Securities Logo"
              title="Growth Securities"
            />
          </a>
          {/* <button className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarstoggle"
            aria-controls="navbarstoggle"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <div className="toggle-btn one"></div>
            <div className="toggle-btn two"></div>
            <div className="toggle-btn three"></div>
          </button> */}
          <div className="searchFilterContainer">
            {/* <SearchFilter /> */}
            <SearchContainer />
          </div>
          <SideNav />
          {/* <Text /> */}
          <div
            className={`${isNavCollapsed ? "collapse" : ""}
                            navbar-collapse justify-content-end miniLaptopNav`}
            id=""
          >
            <ul className="navbar-nav justify-content-end mb-2 mb-lg-0">
              <li className="btn-main btnMain small-tab">
                {/* <SearchFilter /> */}
                <SearchContainer />
              </li>
              <li>
                {/* Mega Dropdown Start */}
                <div className="mega-dropdown">
                  <button className="dropbtn">
                    <span title="about">About</span>
                  </button>
                  <div className="dropAbout dropContent">
                    <div className="inline-style autoHide">
                      <ul className="mega-links">
                        <li
                          className="mega-link"
                          title="Learn more about our company and its mission to provide exceptional services in the financial industry."
                        >
                          <a href="/about-us" className="mega-drop-item">
                            <img src={about} alt="about" className="navLink" />
                            <span>About</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Explore exciting career opportunities and join our team of dedicated professionals."
                        >
                          <a href="/careers" className="mega-drop-item">
                            <img
                              src={careers}
                              alt="career"
                              className="navLink"
                            />
                            <span>Careers</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Discover the various business services and solutions we offer to cater to your financial needs."
                        >
                          <a href="/business" className="mega-drop-item">
                            <img
                              src={business}
                              alt="business"
                              className="navLink"
                            />
                            <span>Business</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Access comprehensive research resources to stay informed about market trends and make informed investment decisions."
                        >
                          <a href="/research" className="mega-drop-item">
                            <img
                              src={research}
                              alt="research"
                              className="navLink"
                            />
                            <span>Research</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Share your valuable feedback with us to help us improve our services and better serve our customers."
                        >
                          <a href="/feedback" className="mega-drop-item">
                            <img
                              src={feedback}
                              alt="feedback"
                              className="navLink"
                            />
                            <span>Feedback</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Explore our blog section to access insightful articles and stay updated with the latest industry news and analysis."
                        >
                          <a href="/blogs" className="mega-drop-item">
                            <img src={blogs} alt="blogs" className="navLink" />
                            <span>Read Blogs</span>
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title="Get in touch with our team for any inquiries, assistance, or collaboration opportunities."
                        >
                          <a href="/contact" className="mega-drop-item">
                            <img
                              src={contact}
                              alt="contact"
                              className="navLink"
                            />
                            <span>Contact Us</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              {/* Mega Dropdown End */}
              {/* *** Mega Drop Start *** */}
              <li>
                <div className="mega-dropdown">
                  <button className="dropbtn">
                    <span title="markets">Markets</span>
                  </button>
                  <div className="dropMega dropContent">
                    <div className="column-container container autoHide">
                      <div className="column-row row">
                        {/* Products */}
                        <div className="mega-column">
                          <h3 className="title">
                            <span>Products</span>
                          </h3>
                          <ul className="mega-links">
                            <li
                              className="mega-link"
                              title=" Invest in government-issued Sovereign Gold Bonds and diversify your investment portfolio with a secure and convenient option."
                            >
                              <img
                                src={bonds}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/bonds-sgb" className="mega-drop-item">
                                Bonds (SGB)
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Start saving now for uncertainties in future with SIP and lumpsum investments."
                            >
                              <img
                                src={mutualFunds}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/mutual-fund" className="mega-drop-item">
                                Mutual Funds
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Buying & selling the shares of listed companies on Indian Exchanges."
                            >
                              <img
                                src={equity}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/equity-trading"
                                className="mega-drop-item"
                              >
                                Equity Trading
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Trade in USD and other foreign currencies & hedge yourself."
                            >
                              <img
                                src={currency}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/currency-trading"
                                className="mega-drop-item"
                              >
                                Currency Trading
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Trading in F&O contracts available on NSE & BSE of India."
                            >
                              <img
                                src={derivative}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/derivative-trading"
                                className="mega-drop-item"
                              >
                                Derivative Trading
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Trading made easy in global commodities like precious metals, agri-products, energy, etc."
                            >
                              <img
                                src={commodity}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/commodity-trading"
                                className="mega-drop-item"
                              >
                                Commodity Trading
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Harnessing automation for executing trades based on predefined rules to tap market opportunities."
                            >
                              <img
                                src={algo}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/algorithmic-trading"
                                className="mega-drop-item"
                              >
                                Algorithmic Trading
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Leveraging advanced algorithms to conduct rapid and high-volume trades with exceptional accuracy & results."
                            >
                              <img
                                src={hft}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/high-frequency-trading"
                                className="mega-drop-item"
                              >
                                High Frequency Trading
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* Services */}
                        <div className="mega-column">
                          <h3 className="title">
                            <span>Services</span>
                          </h3>
                          <ul className="mega-links">
                            <li
                              className="mega-link"
                              title="Invest in IPOs from the comfort of your house with a few clicks."
                            >
                              <img
                                src={ipo}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/initial-public-offering-ipo"
                                className="mega-drop-item"
                              >
                                IPO
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Explore Exchange-Traded Funds (ETFs) and invest in a diverse range of assets to achieve your financial goals."
                            >
                              <img
                                src={etf}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/exchange-traded-fund"
                                className="mega-drop-item"
                              >
                                ETF
                              </a>
                            </li>
                            {/* <li
                              className="mega-link"
                              title="Join the Growth Securities family and start referring today. Your network, your rewards."
                            >
                              <img
                                src={refer}
                                alt="Nav Link Illustration"
                                className="navLink"
                                title="coming soon"
                              />
                              <a href="#refer" className="mega-drop-item">
                                Refer
                              </a>
                            </li> */}
                            <li
                              className="mega-link"
                              title="Access our comprehensive support services to get assistance with any queries, issues, or technical support needs."
                            >
                              <img
                                src={help}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/support" className="mega-drop-item">
                                Supports
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Learn about our depository services and securely hold your securities in electronic form for easy management and transactions."
                            >
                              <img
                                src={depository}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/depository" className="mega-drop-item">
                                Depository
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Transfer funds seamlessly and securely between accounts or to other beneficiaries using our convenient fund transfer services."
                            >
                              <img
                                src={transfer}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/fund-transfer"
                                className="mega-drop-item"
                              >
                                Fund Transfer
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Get detailed information about our bank depository participant (DP) services and how to manage your securities and investments."
                            >
                              <img
                                src={bankDP}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/bankdetails" className="mega-drop-item">
                                Bank DP Details
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Unlock stock market insights through curated articles, guides, and resources."
                            >
                              <img
                                src={knowledgeCenter}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="/knowledgeCenter"
                                className="mega-drop-item"
                              >
                                Knowledge Center
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* Tools */}
                        <div className="mega-column">
                          <h3 className="title">
                            <span>Tools</span>
                          </h3>
                          <ul className="mega-links">
                            <li
                              className="mega-link"
                              title=" Gain valuable insights into the stock market with a comprehensive summary of market trends, news, and key indicators."
                            >
                              <img
                                src={summary}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/summary" className="mega-drop-item">
                                Summary
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Access a variety of calculators to help you make informed financial decisions, including sip, lumpsum, cagr, etc."
                            >
                              <img
                                src={calc}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/calculators" className="mega-drop-item">
                                Calculators
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Test your knowledge of the stock market with our interactive quiz. Challenge yourself with questions about investing, market terminology, and more."
                            >
                              <img
                                src={quiz}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/quizCenter" className="mega-drop-item">
                                Quiz Center
                              </a>
                            </li>
                            {/* <li
                              className="mega-link"
                              title="Stay updated with the latest stock market news, trends, and analysis."
                            >
                              <img
                                src={news}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a href="/news" className="mega-drop-item">
                                Latest News
                              </a>
                            </li>
                            <li className="mega-link" title="Coming Soon">
                              <img
                                src={simulation}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="#view"
                                className="mega-drop-item"
                                aria-disabled={true}
                              >
                                Market View
                              </a>
                            </li>
                            <li
                              className="mega-link"
                              title="Foreign Institutional Investors (FIIs) and Domestic Institutional Investors (DIIs)"
                            >
                              <img
                                src={indicator}
                                alt="Nav Link Illustration"
                                className="navLink"
                              />
                              <a
                                href="fiidii"
                                className="mega-drop-item"
                                aria-disabled={true}
                              >
                                FII & DII Activity
                              </a>
                            </li> */}
                          </ul>
                        </div>
                        <div className="mega-column">
                          <NavCar />
                          <br />
                          <Weather />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* ***Mega Drop End *** */}
              <li>
                {/* Mega Dropdown Start */}
                <div className="mega-dropdown">
                  <button className="dropbtn">
                    <span title="accounts">Accounts</span>
                  </button>
                  <div className="dropAccount dropContent">
                    <div className="accounts-style autoHide">
                      <ul className="mega-links">
                        <li
                          className="mega-link"
                          title="Easily re-activate your dormant or inactive account with Growth Securities. Regain access to your investment portfolio and resume trading with just a few simple steps."
                        >
                          <img
                            src={activate}
                            alt="re activate"
                            className="navLink"
                          />
                          <a
                            href="https://rekyc.growthsec.com/v1/company/growth/modification/login"
                            className="mega-drop-item"
                          >
                            Re-Activate
                          </a>
                        </li>
                        <li
                          className="mega-link"
                          title=" Gain control over your trading activities with our advanced Risk Management System (RMS). Monitor your risk exposure, set trading limits, and ensure compliance with regulatory requirements for a secure and efficient trading experience."
                        >
                          <img src={risk} alt="risk" className="navLink" />
                          <a
                            href="http://rms.growthsec.in:9999/WEBR/#noback"
                            className="mega-drop-item"
                          >
                            RMS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
              {/* Mega Dropdown End */}
              <li className="btn-main small-tab">
                <a
                  className="btn btn-account btnSec"
                  href="https://kyc.growthsec.com"
                  title=" Start your investment journey with Growth Securities by opening a hassle-free Demat account. Enjoy seamless access to the stock market and a wide range of financial instruments."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open Account
                </a>
              </li>
              <li className="btn-main btnMain small-tab">
                <a
                  href="https://trade.growthsec.com/#/"
                  className="btn btn-trade"
                  title="Access our advanced trading platform and execute trades in real-time. Take advantage of our comprehensive market analysis, powerful trading tools, and seamless order execution."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trade Now
                </a>
              </li>
              <li className="btn-main btnMain small-tab">
                <a
                  href="https://reports.growthsec.com/"
                  className="btn btn-bo"
                  title="Manage your investments, track your portfolio, and access essential account information through our user-friendly Back Office platform. Stay updated on your trades, holdings, and financial reports."
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reports
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
