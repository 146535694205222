// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.terms-container {
  width: 100%;
  overflow: hidden;
}
.terms-container .term-text ul li.list {
  font-family: "Poppins";
  list-style: none;
  font-size: 1.1rem;
  padding-bottom: 20px;
  cursor: default;
}
.terms-container .term-text ul li.list::before {
  content: "➧";
  position: absolute;
  margin-left: -28px;
  color: #269ed6;
}
.terms-container .account-texts {
  padding-top: 10%;
}
.terms-container h2.subtitle {
  color: #808080;
  font-family: "Poppins";
}
.terms-container a {
  text-decoration: none;
  color: #269ed6;
}
.terms-container a:hover {
  text-decoration: underline;
}

.trading-container {
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  max-height: 150vh;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/TermsConditions/Style.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAKhB;EACI,WAAA;EACA,gBAAA;AAHJ;AAMQ;EACI,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;AAJZ;AAMY;EACI,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,cApBL;AAgBX;AASI;EACI,gBAAA;AAPR;AAUI;EACI,cA7BE;EA8BF,sBAAA;AARR;AAYI;EACI,qBAAA;EACA,cArCG;AA2BX;AAYQ;EACI,0BAAA;AAVZ;;AAgBA;EACI,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;AAbJ","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n// **** Styles\r\n.terms-container {\r\n    width: 100%;\r\n    overflow: hidden;\r\n    \r\n    .term-text {\r\n        ul li.list {\r\n            font-family: \"Poppins\";\r\n            list-style: none;\r\n            font-size: 1.1rem;\r\n            padding-bottom: 20px;\r\n            cursor: default;\r\n\r\n            &::before {\r\n                content: \"➧\";\r\n                position: absolute;\r\n                margin-left: -28px;\r\n                color: $main-clr;\r\n            }\r\n        }\r\n    }\r\n\r\n    .account-texts {\r\n        padding-top: 10%;\r\n    }\r\n\r\n    h2.subtitle {\r\n        color: $sec-clr;\r\n        font-family: \"Poppins\";\r\n    }\r\n\r\n// ***** Pay Section\r\n    a {\r\n        text-decoration: none;\r\n        color: $main-clr;\r\n\r\n        &:hover {\r\n            text-decoration: underline;\r\n        }\r\n    }\r\n}\r\n\r\n// *** Trading Segments\r\n.trading-container {\r\n    width: 100%;\r\n    overflow: hidden;\r\n    min-height: 100vh;\r\n    max-height: 150vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
