// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stock-market-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.stock-market-container .stock-data {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.stock-market-container .stock-data h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.stock-market-container .stock-data p {
  font-size: 18px;
  margin: 5px 0;
}
.stock-market-container .ipo-calendar {
  margin-top: 20px;
}
.stock-market-container .ipo-calendar h2 {
  font-size: 24px;
}
.stock-market-container .ipo-calendar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.stock-market-container .ipo-calendar ul li {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}
.stock-market-container .ipo-calendar ul li strong {
  font-size: 18px;
}
.stock-market-container .ipo-calendar ul li p {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/Component/StockNews/Style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AACI;EACI,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uCAAA;AACR;AACQ;EACI,eAAA;EACA,mBAAA;AACZ;AAEQ;EACI,eAAA;EACA,aAAA;AAAZ;AAII;EACI,gBAAA;AAFR;AAIQ;EACI,eAAA;AAFZ;AAKQ;EACI,gBAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;EACA,eAAA;AAHZ;AAKY;EACI,UAAA;EACA,aAAA;EACA,sBAAA;AAHhB;AAKgB;EACI,eAAA;AAHpB;AAMgB;EACI,eAAA;AAJpB","sourcesContent":[".stock-market-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 20px;\r\n\r\n    .stock-data {\r\n        background-color: #f0f0f0;\r\n        padding: 20px;\r\n        border-radius: 10px;\r\n        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\r\n\r\n        h2 {\r\n            font-size: 24px;\r\n            margin-bottom: 10px;\r\n        }\r\n\r\n        p {\r\n            font-size: 18px;\r\n            margin: 5px 0;\r\n        }\r\n    }\r\n\r\n    .ipo-calendar {\r\n        margin-top: 20px;\r\n\r\n        h2 {\r\n            font-size: 24px;\r\n        }\r\n\r\n        ul {\r\n            list-style: none;\r\n            padding: 0;\r\n            margin: 0;\r\n            display: flex;\r\n            flex-wrap: wrap;\r\n\r\n            li {\r\n                width: 50%;\r\n                padding: 10px;\r\n                box-sizing: border-box;\r\n\r\n                strong {\r\n                    font-size: 18px;\r\n                }\r\n\r\n                p {\r\n                    font-size: 16px;\r\n                }\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
