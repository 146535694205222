// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mendatoryDetails {
  width: 100%;
  min-height: 100vh;
}

.escalationMatrixContainer {
  margin-top: 5%;
}

.detailsContainer {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 15px;
  overflow: scroll;
}
.detailsContainer h3 {
  color: #269ed6;
}

.kmpContainer {
  margin-top: 3%;
}

.footerDetailsContainer {
  padding: 4% 0;
}
.footerDetailsContainer .detailSectionHere {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 15px;
}
.footerDetailsContainer a {
  color: #000;
  text-decoration: none;
}
.footerDetailsContainer a:hover {
  color: #269ed6;
}`, "",{"version":3,"sources":["webpack://./src/Component/Mandatory/Style.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,2CAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,cAAA;AACR;;AAMA;EACI,cAAA;AAHJ;;AAOA;EACI,aAAA;AAJJ;AAMI;EACI,2CAAA;EACA,aAAA;EACA,mBAAA;AAJR;AAOI;EACI,WAAA;EACA,qBAAA;AALR;AAOQ;EACI,cAAA;AALZ","sourcesContent":[".mendatoryDetails {\r\n    width: 100%;\r\n    min-height: 100vh;\r\n}\r\n\r\n.escalationMatrixContainer {\r\n    margin-top: 5%;\r\n}\r\n\r\n.detailsContainer {\r\n    box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);\r\n    padding: 20px;\r\n    border-radius: 15px;\r\n    overflow: scroll;\r\n\r\n    h3 {\r\n        color: #269ed6;\r\n    }\r\n\r\n}\r\n\r\n\r\n// KMP\r\n.kmpContainer {\r\n    margin-top: 3%;\r\n}\r\n\r\n// FooterDetails\r\n.footerDetailsContainer {\r\n    padding: 4% 0;\r\n\r\n    .detailSectionHere {\r\n        box-shadow: 0px 3px 10px rgb(0 0 0 / 0.2);\r\n        padding: 20px;\r\n        border-radius: 15px;\r\n    }\r\n\r\n    a {\r\n        color: #000;\r\n        text-decoration: none;\r\n\r\n        &:hover {\r\n            color: #269ed6;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
