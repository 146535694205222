// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nopageContainer {
  height: 100vh;
}
.nopageContainer .noPage {
  margin-top: 12%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.nopageContainer .noPage .nopage-title {
  font-size: 2rem;
  color: #269ed6;
  margin-bottom: 0.5rem;
}
.nopageContainer .noPage .errorImg {
  width: 100%;
  height: 50vh;
  margin-bottom: 1rem;
}
.nopageContainer .noPage .nopage-description {
  font-size: 1rem;
  color: #777;
  text-align: center;
  margin-bottom: 1.5rem;
}
.nopageContainer .noPage .nopage-link {
  padding: 10px 20px;
  background-color: #269ed6;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.nopageContainer .noPage .nopage-link:hover {
  background-color: #207ab0;
}`, "",{"version":3,"sources":["webpack://./src/Component/NoPage/Style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,eAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;AACJ;AACI;EACE,eAAA;EACA,cAAA;EACA,qBAAA;AACN;AAEI;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AAAN;AAGI;EACE,eAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;AADN;AAII;EACE,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,qBAAA;EACA,kBAAA;EACA,sCAAA;AAFN;AAIM;EACE,yBAAA;AAFR","sourcesContent":[".nopageContainer {\r\n  height: 100vh;\r\n\r\n  .noPage {\r\n    margin-top: 12%;\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    text-align: center;\r\n    \r\n    .nopage-title {\r\n      font-size: 2rem;\r\n      color: #269ed6;\r\n      margin-bottom: 0.5rem;\r\n    }\r\n    \r\n    .errorImg {\r\n      width: 100%;\r\n      height: 50vh;\r\n      margin-bottom: 1rem;\r\n    }\r\n    \r\n    .nopage-description {\r\n      font-size: 1rem;\r\n      color: #777;\r\n      text-align: center;\r\n      margin-bottom: 1.5rem;\r\n    }\r\n    \r\n    .nopage-link {\r\n      padding: 10px 20px;\r\n      background-color: #269ed6;\r\n      color: white;\r\n      text-decoration: none;\r\n      border-radius: 5px;\r\n      transition: background-color 0.3s ease;\r\n      \r\n      &:hover {\r\n        background-color: #207ab0;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
