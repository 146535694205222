// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.market-view {
  min-height: 100vh;
}

.market-cards {
  display: grid;
  grid-auto-flow: column;
}

.market-card {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.market-card .symbol,
.market-card .open,
.market-card .high,
.market-card .low,
.market-card .close,
.market-card .volume {
  font-size: 16px;
  margin: 5px 0;
}`, "",{"version":3,"sources":["webpack://./src/Component/MarketView/Style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF;;AACA;EACE,sBAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,wCAAA;AAEF;AAAE;;;;;;EAME,eAAA;EACA,aAAA;AAEJ","sourcesContent":[".market-view {\r\n  min-height: 100vh;\r\n}\r\n.market-cards {\r\n  display: grid;\r\n  grid-auto-flow: column;\r\n}\r\n\r\n.market-card {\r\n  background-color: #fff;\r\n  border: 1px solid #ccc;\r\n  padding: 15px;\r\n  margin: 10px;\r\n  border-radius: 5px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n\r\n  .symbol,\r\n  .open,\r\n  .high,\r\n  .low,\r\n  .close,\r\n  .volume {\r\n    font-size: 16px;\r\n    margin: 5px 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
