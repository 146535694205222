// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../Icons/brokerback.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.broker_norms-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
}
.broker_norms-container h2.subtitle {
  font-family: "Poppins";
}
.broker_norms-container h5.disc_title {
  font-family: "Poppins";
  color: #808080;
}`, "",{"version":3,"sources":["webpack://./src/Component/Supports/BrokerNorms/Style.scss"],"names":[],"mappings":"AAKA;EACI,yDAAA;EACA,4BAAA;AAJJ;AAOI;EACI,sBAAA;AALR;AAQI;EACI,sBAAA;EACA,cAdE;AAQV","sourcesContent":["// ?? Main Variables\r\n$main-clr: #269ed6;\r\n$sec-clr: #808080;\r\n\r\n// ***** Broker Norms Page ***** \r\n.broker_norms-container {\r\n    background-image: url(../Icons/brokerback.png);\r\n    background-repeat: no-repeat;\r\n\r\n\r\n    h2.subtitle {\r\n        font-family: 'Poppins';\r\n    }\r\n\r\n    h5.disc_title {\r\n        font-family: \"Poppins\";\r\n        color: $sec-clr;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
